<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4>
                     تقييمات الطلاب
                </h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                        <label for="">المعلم</label>
                        <select class="form-control" v-model="teacher" @change="filter()">
                            <option value="all">الكل</option>
                            <option v-for="t in teachers" :key="t" :value="t">
                                {{ t }}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                        <label for="">الفصل</label>
                        <select class="form-control" v-model="classroom" @change="filter()">
                            <option value="all">الكل</option>
                            <option v-for="t in classrooms" :key="t" :value="t">
                                {{ t }}
                            </option>
                        </select>
                        </div>
                    </div>
                </div>
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>
                                    الطالب
                                </th>
                                <th>
                                    المعلم
                                </th>
                                <th>
                                    التقييم
                                </th>
                                <th>
                                    التاريخ	
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rate in rates" :key="rate._id">
                                <td>
                                    {{ rate.student_name }}
                                </td>
                                <td>
                                    {{ rate.teacher_name }}
                                </td>
                                <td>
                                    {{ rate.content }}
                                </td>
                                <td>
                                    {{ rate.date }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        vSelect,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            rates: [],
            teachers: [],
            teacher: "all",
            classrooms: [],
            classroom: "all",
            original: [],
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
        if(!checkPer("students|rates")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getrates();
    },
    methods: {
        filter(){
            var g = this, arr = [];
            g.rates = g.original;
            g.rates.forEach(function(a){
                var add = false;
                if(g.teacher == a.teacher_name || g.teacher == "all"){
                    add = true;
                }
                if(g.classroom == a.classroom || g.classroom == "all"){
                    if(add){
                        add = true;
                    }
                }else{
                    add = false;
                }
                if(add){
                    arr.push(a)
                }
            })
            g.rates = arr;
        },
        getrates(){
            var g = this;
            $.post(api + '/user/students/rates', {
                jwt: this.user.jwt,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.rates = r.response;
                    g.original = r.response;
                    g.rates.forEach(function(a){
                        if(!g.classrooms.includes(a.classroom)){
                            g.classrooms.push(a.classroom)
                        }
                        if(!g.teachers.includes(a.teacher_name)){
                            g.teachers.push(a.teacher_name)
                        }
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
#vs3__listbox{
    max-height: 150px !important;
    overflow: hidden !important;
}
</style>